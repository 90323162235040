<template>
  <ContentBlock>
    <div class="top" style="padding: 12px 12px 0 20px;" v-if="isShowHead">
      <b>联系与结算信息</b>
    </div>
    <div class="materialContent">
      <div class="left">
        <ul class="navContainer">
          <li v-for="material in MaterialLists" :key="material.label" :class="{'active': activeName === material.value, 'required': material.required}" @click="handleClick(material.value, activeName)">
            <span>{{ material.label }}</span> <i class="el-icon-info" v-if="material.showError"  style="color:red;font-size: 14px;margin-top:2px;"></i>
          </li>
        </ul>
      </div>
      <div class="right">
        <CorporateContacts
          v-show="activeName==='CorporateContacts'"
          ref="JZCorporateContacts"
          :activeName="activeName"
          :concatLists="concatLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          @updateContact="updateContact"
        >
        </CorporateContacts>
        <FinancialContact
          v-show="activeName==='FinancialContact'"
          ref="JZFinancialContact"
          :concatLists="concatLists"
          :isDisabled="isDisabled"
          :activeName="activeName"
          :isShowHead="isShowHead"
          @updateContact="updateContact"
        >
        </FinancialContact>
        <ActualContacts
          v-show="activeName==='ActualContacts'"
          ref="JZActualContacts"
          :concatLists="concatLists"
          :isDisabled="isDisabled"
          :isShowHead="isShowHead"
          :activeName="activeName"
          @updateContact="updateContact"
        >
        </ActualContacts>
        <InvoicingInformation
          v-show="activeName==='InvoicingInformation'"
          ref="JZInvoicingInformation"
          :invoiceInfo="invoiceInfo"
          :isDisabled="isDisabled"
          :activeName="activeName"
          :isShowHead="isShowHead"
          @updateInvoice="updateInvoice"
        ></InvoicingInformation>
      </div>

    </div>
  </ContentBlock>
  <!-- <ContentBlock v-else>
    <el-empty description="暂未维护数据" :image="require('@/assets/empty.png')"></el-empty>
  </ContentBlock> -->
</template>

<script>
import {updateTenantInformation} from '@/api/systemSetting';
export default {
  name: '',
  props: {
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    currentInfo: {
      type: Object,
      default () {
        return {};
      },
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  components: {
    FinancialContact: ()=>import('./component/FinancialContact.vue'),
    CorporateContacts: ()=>import('./component/CorporateContacts.vue'),
    ActualContacts: ()=>import('./component/ActualContacts.vue'),
    InvoicingInformation: ()=>import('./component/InvoicingInformation.vue'),
  },
  methods: {
    handleClick (value, preActiveName) {
      if (value !== preActiveName) {
        if (this.$route.name === 'CorporateCertification') {
          // this.handleUpdateContact(preActiveName);
        } else {
          this.handleUpdateCancel(preActiveName);
        }
        this.activeName = value;
      }
    },
    handleUpdateCancel (preActiveName) {
      if (preActiveName === 'CorporateContacts') {
        this.$refs.JZCorporateContacts.handleCancel();
      } else if (preActiveName === 'FinancialContact') {
        this.$refs.JZFinancialContact.handleCancel();
      } else if (preActiveName === 'ActualContacts') {
        this.$refs.JZActualContacts.handleCancel();
      } else if (preActiveName === 'InvoicingInformation') {
        this.$refs.JZInvoicingInformation.handleCancel();
      }
    },
    handleUpdateContact (preActiveName) {
      if (preActiveName === 'CorporateContacts') {
        this.$refs.JZCorporateContacts.handleUpdate();
      } else if (preActiveName === 'FinancialContact') {
        this.$refs.JZFinancialContact.handleUpdate();
      } else if (preActiveName === 'ActualContacts') {
        this.$refs.JZActualContacts.handleUpdate();
      } else if (preActiveName === 'InvoicingInformation') {
        this.$refs.JZInvoicingInformation.handleUpdate();
      }
    },
    updateContact (form, contactType) {
      let concatLists = JSON.parse(JSON.stringify(this.concatLists));
      let typeLists = concatLists.map(i => i.contactType);
      concatLists = concatLists.map(i => ({
        ...i,
        operateType: i.id ? '2' : '1',
      }));
      if (typeLists.includes(contactType)) {
        concatLists = concatLists.map(i => {
          if (i.contactType === contactType) {
            return {...form, contactType};
          }
          return i;
        });
      } else {
        concatLists.push({
          ...form,
          operateType: '1',
          contactType,
        });
      }
      if (this.$route.name === 'CorporateCertification') {
        this.$emit('updateData', concatLists, 'tenantContactInfoRespDTOs');
      } else if (['CompanyProfile'].includes(this.$route.name)) {
        let currentParams = {
          dataType: '1',
          tenantContactInfoReqDTOs: concatLists,
        };
        updateTenantInformation(currentParams).then(() => {
          this.$emit('updatePage');
        });
      }
    },
    updateInvoice (info) {
      if (this.$route.name === 'CorporateCertification') {
        this.$emit('updateData', info, 'tenantInvoiceInfoRespDTO');
      } else if (['CompanyProfile'].includes(this.$route.name)) {
        let currentParams = {
          dataType: '1',
          tenantInvoiceInfoReqDTO: info,
        };
        updateTenantInformation(currentParams).then(() => {
          this.$emit('updatePage');
        });
      }
    },
    checkForm (list) {
      let resultLists = [];
      let results = list.map(name => {
        return new Promise((resolve, reject) => {
          let formObj = null;
          formObj = this.$refs[name].$refs.JZForm;
          formObj.validate((valid, object) => {
            if (valid) {
              resolve();
            } else {
              resultLists.push({
                ...object,
                name,
              });
              reject(new Error('验证失败'));
              this.$nextTick(() => {
                var isError = document.querySelectorAll('.is-error');
                isError[0].querySelector('input').focus();
              });
            }
          });
        });
      });
      return Promise.all(results).catch(() => {
        return Promise.reject(resultLists);
      });
    },
    async handleValidateForm () {
      this.errorFormLists = [];
      return await this.checkForm(this.formArr).then((valid) => {
        if (valid) {
          return true;
        }
      }).catch((err)=>{
        this.errorFormLists = (err || []).map(i => i.name);
        if (this.errorFormLists && this.errorFormLists.length) {
          this.$message.warning('请补充完成必填信息');
          return false;
        }
        return false;
      });
    },
    // 这里获取
    getTenantContactInfoRespDTOs () {
      // this.handleUpdateContact(this.activeName);
      this.tempConcatLists = JSON.parse(JSON.stringify(this.concatLists));
      let form = this.$refs.JZCorporateContacts.form;
      this.getConcatLists(form, '1');
      form = this.$refs.JZFinancialContact.form;
      this.getConcatLists(form, '2');
      form = this.$refs.JZActualContacts.form;
      this.getConcatLists(form, '3');
      this.$emit('updateData', this.tempConcatLists, 'tenantContactInfoRespDTOs');
      return this.tempConcatLists;
    },
    getTenantBaseInfoRespDTO () {
      let tenantInvoiceInfoRespDTO = this.$refs.JZInvoicingInformation.form;
      this.$emit('updateData', tenantInvoiceInfoRespDTO, 'tenantInvoiceInfoRespDTO');
      return tenantInvoiceInfoRespDTO;
    },
    getConcatLists (form, contactType) {
      let tempConcatLists = JSON.parse(JSON.stringify(this.tempConcatLists));
      let typeLists = tempConcatLists.map(i => i.contactType);
      tempConcatLists = tempConcatLists.map(i => ({
        ...i,
        operateType: i.id ? i.operateType : '1',
      }));
      if (typeLists.includes(contactType)) {
        tempConcatLists = tempConcatLists.map(i => {
          if (i.contactType === contactType) {
            return {...form, contactType};
          }
          return i;
        });
      } else {
        tempConcatLists.push({
          ...form,
          operateType: '1',
          contactType,
        });
      }
      this.tempConcatLists = tempConcatLists || [];
    },
  },
  watch: {
    currentInfo: {
      handler (val) {
        if (val && val.tenantContactInfoRespDTOs) {
          this.concatLists = val.tenantContactInfoRespDTOs || [];
          this.invoiceInfo = {
            ...val.tenantInvoiceInfoRespDTO,
            ...val.tenantBaseInfoRespDTO,
            dutyParagraph: val.tenantBaseInfoRespDTO.certCode,
          } || {};
        }
      },
      immediate: true,
      deep: true,
    },
    isShowEditing: {
      handler () {
        this.isEditing = this.isShowEditing;
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    formArr () {
      return ['JZCorporateContacts', 'JZActualContacts', 'JZInvoicingInformation'];
    },
    MaterialLists () {
      let errorFormLists = this.errorFormLists;
      return [
        {label: '企业联系人', value: 'CorporateContacts', type: '1', required: this.isShowHead, showError: errorFormLists.includes('JZCorporateContacts')},
        {label: '财务联系人', value: 'FinancialContact', type: '2' },
        {label: '公司实际经营管理人', value: 'ActualContacts', type: '3', required: this.isShowHead, showError: errorFormLists.includes('JZActualContacts')},
        {label: '开票信息', value: 'InvoicingInformation', type: '14', required: this.isShowHead, showError: errorFormLists.includes('JZInvoicingInformation')},
      ];
    },
  },
  data () {
    return {
      activeName: 'CorporateContacts',
      concatLists: [],
      invoiceInfo: {},
      isEditing: false,
      errorFormLists: [],
      tempConcatLists: [],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 0;
  min-height: calc(100vh - 420px);
}
.navContainer{
  list-style: none;
  width: 180px;
  margin-bottom: 0;

  margin-right: 10px;
  li{
    height:40px;
    line-height: 40px;
    margin-bottom: 0;
    padding-left: 20px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #5F6A7A;
    letter-spacing: 0;
    text-align: left;
    line-height: 20px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    &.active{
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #237FFA;
      letter-spacing: 0;
      text-align: left;
      line-height: 20px;
      background: #E2F3FE;
      position: relative;
      &::after{
        content: '';
        width:2px;
        height:20px;
        position: absolute;
        left: 0;
        top: 10px;
        background: #237FFA;
      }
    }
    &.required{
      position: relative;
      display: flex;
      span{
        flex: 1;
      }
      &::before{
        content: '*';
        width:8px;
        height:8px;
        position: absolute;
        left: 10px;
        top: 12px;
        color: #FF5959;
      }
    }
  }
}
.materialContent{
  display: flex;
  padding-top: 8px;
  padding-right: 10px;
  .right{
    flex: 1;
    background: #FFFFFF;
    border: 1px solid #DFE2E8;
    border-radius: 4px;
  }
}
</style>
